.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}
.Input {
	border: var(--light-1) !important;
}
.error-input-border {
	border: 1px solid var(--red) !important;
}
.error-message {
	color: var(--red);
}
@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.primary-color-font {
	color: var(--brand-1) !important;
}

.highlight {
	background-color: var(--brand-3) !important;
}

#chat-container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
}

.Input {
	font-size: 1rem;
}

.route {
	color: black;
	margin-right: 1rem;
}

.center-text-align {
	text-align: center;
}
