.popover-list {
	width: 30%;
	overflow-y: auto;
	border-right: 1px solid var(--gray-5);
	border-top: 1px solid var(---gray-5) !important;
}

.selected-template {
	background-color: var(--gray-6);
}
