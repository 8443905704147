@font-face {
	font-family: "isc-icons";
	src: url("https://storage.googleapis.com/isc-stage-cdn-bucket/fonts/isc/v3.1.0/isc-icons.eot");
	src: url("https://storage.googleapis.com/isc-stage-cdn-bucket/fonts/isc/v3.1.0/isc-icons.eot") format("embedded-opentype"),
		url("https://storage.googleapis.com/isc-stage-cdn-bucket/fonts/isc/v3.1.0/isc-icons.ttf") format("truetype"),
		url("https://storage.googleapis.com/isc-stage-cdn-bucket/fonts/isc/v3.1.0/isc-icons.woff") format("woff"),
		url("https://storage.googleapis.com/isc-stage-cdn-bucket/fonts/isc/v3.1.0/isc-icons.svg") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

i {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "isc-icons" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.isc-collapse-alt:before {
	content: "\e9f2";
}
.isc-expand-alt:before {
	content: "\e9f3";
}
.isc-clock-solid:before {
	content: "\e9f4";
}
.isc-home:before {
	content: "\e9f1";
}
.isc-thumbs-line:before {
	content: "\e9f0";
}
.isc-secondary-advisor-icon:before {
	content: "\e9ed";
}
.isc-primary-advisor-icon:before {
	content: "\e9ee";
}
.isc-video-filled:before {
	content: "\e9ef";
}
.isc-ad-stats:before {
	content: "\e9e9";
}
.isc-lead-score:before {
	content: "\e9ea";
}
.isc-lead-stats:before {
	content: "\e9eb";
}
.isc-sales-stats:before {
	content: "\e9ec";
}
.isc-note:before {
	content: "\e9e8";
}
.isc-comment:before {
	content: "\e9e6";
}
.isc-user-check:before {
	content: "\e9e7";
}
.isc-clipboard:before {
	content: "\e9e5";
}
.isc-clipboard-check:before {
	content: "\e9e2";
}
.isc-filter-filled:before {
	content: "\e9e3";
}
.isc-sort-filled:before {
	content: "\e9e4";
}
.isc-commission:before {
	content: "\e9dd";
}
.isc-eye:before {
	content: "\e9de";
}
.isc-eye-slash:before {
	content: "\e9df";
}
.isc-shopping-bag:before {
	content: "\e9e0";
}
.isc-download-solid:before {
	content: "\e9e1";
}
.isc-deadline:before {
	content: "\e9db";
}
.isc-degree:before {
	content: "\e9dc";
}
.isc-heart-solid:before {
	content: "\e9d8";
}
.isc-heart:before {
	content: "\e9d9";
}
.isc-school:before {
	content: "\e9da";
}
.isc-brush:before {
	content: "\e9d3";
}
.isc-email-solid:before {
	content: "\e9d4";
}
.isc-gsettings:before {
	content: "\e9d5";
}
.isc-api:before {
	content: "\e9d6";
}
.isc-chat-messages:before {
	content: "\e9d7";
}
.isc-circle:before {
	content: "\e9b3";
}
.isc-circle-filled:before {
	content: "\e9d1";
}
.isc-square:before {
	content: "\e9d2";
}
.isc-strength:before {
	content: "\e9b1";
}
.isc-weakness:before {
	content: "\e9b2";
}
.isc-bookmark:before {
	content: "\e9b4";
}
.isc-billing:before {
	content: "\e9b5";
}
.isc-category-solid:before {
	content: "\e9b6";
}
.isc-candidate:before {
	content: "\e9b7";
}
.isc-acceptance-rate:before {
	content: "\e9b8";
}
.isc-bookmark-solid:before {
	content: "\e9b9";
}
.isc-building:before {
	content: "\e9ba";
}
.isc-calendar:before {
	content: "\e9bb";
}
.isc-certificate:before {
	content: "\e9bc";
}
.isc-chart:before {
	content: "\e9bd";
}
.isc-cloud-upload:before {
	content: "\e9be";
}
.isc-dashboard:before {
	content: "\e9bf";
}
.isc-gear:before {
	content: "\e9c0";
}
.isc-info-solid:before {
	content: "\e9c1";
}
.isc-location-marker:before {
	content: "\e9c2";
}
.isc-manager:before {
	content: "\e9c3";
}
.isc-meeting:before {
	content: "\e9c4";
}
.isc-menu:before {
	content: "\e9c5";
}
.isc-money-bag:before {
	content: "\e9c6";
}
.isc-notification:before {
	content: "\e9c7";
}
.isc-partner:before {
	content: "\e9c8";
}
.isc-rank:before {
	content: "\e9c9";
}
.isc-search:before {
	content: "\e9ca";
}
.isc-sort-solid:before {
	content: "\e9cb";
}
.isc-sort-solid-down:before {
	content: "\e9cc";
}
.isc-sort-solid-up:before {
	content: "\e9cd";
}
.isc-stats:before {
	content: "\e9ce";
}
.isc-student:before {
	content: "\e9cf";
}
.isc-tasks:before {
	content: "\e9d0";
}
.isc-two-way:before {
	content: "\e900";
}
.isc-one-way:before {
	content: "\e901";
}
.isc-screen-share:before {
	content: "\e902";
}
.isc-angle-double-down-solid:before {
	content: "\e903";
}
.isc-angle-double-left-solid:before {
	content: "\e904";
}
.isc-angle-double-right-solid:before {
	content: "\e905";
}
.isc-angle-double-up-solid:before {
	content: "\e906";
}
.isc-angle-down-solid:before {
	content: "\e907";
}
.isc-angle-left-solid:before {
	content: "\e908";
}
.isc-angle-right-solid:before {
	content: "\e909";
}
.isc-angle-up-solid:before {
	content: "\e90a";
}
.isc-arrow-alt-circle-down:before {
	content: "\e90b";
}
.isc-arrow-alt-circle-down-solid:before {
	content: "\e90c";
}
.isc-arrow-alt-circle-left:before {
	content: "\e90d";
}
.isc-arrow-alt-circle-left-solid:before {
	content: "\e90e";
}
.isc-arrow-alt-circle-right:before {
	content: "\e90f";
}
.isc-arrow-alt-circle-right-solid:before {
	content: "\e910";
}
.isc-arrow-alt-circle-up:before {
	content: "\e911";
}
.isc-arrow-alt-circle-up-solid:before {
	content: "\e912";
}
.isc-arrow-circle-down-solid:before {
	content: "\e913";
}
.isc-arrow-circle-left-solid:before {
	content: "\e914";
}
.isc-arrow-circle-right-solid:before {
	content: "\e915";
}
.isc-arrow-circle-up-solid:before {
	content: "\e916";
}
.isc-arrow-down-solid:before {
	content: "\e917";
}
.isc-arrow-left-solid:before {
	content: "\e918";
}
.isc-arrow-right-solid:before {
	content: "\e919";
}
.isc-arrow-up-solid:before {
	content: "\e91a";
}
.isc-arrows-alt-h-solid:before {
	content: "\e91b";
}
.isc-arrows-alt-solid:before {
	content: "\e91c";
}
.isc-arrows-alt-v-solid:before {
	content: "\e91d";
}
.isc-bell:before {
	content: "\e91e";
}
.isc-bell-slash:before {
	content: "\e91f";
}
.isc-calendar-1:before {
	content: "\e920";
}
.isc-calendar-plus:before {
	content: "\e921";
}
.isc-check-circle:before {
	content: "\e922";
}
.isc-check-double-solid:before {
	content: "\e923";
}
.isc-check-solid:before {
	content: "\e924";
}
.isc-check-square:before {
	content: "\e925";
}
.isc-chevron-circle-down-solid:before {
	content: "\e926";
}
.isc-chevron-circle-left-solid:before {
	content: "\e927";
}
.isc-chevron-circle-right-solid:before {
	content: "\e928";
}
.isc-chevron-circle-up-solid:before {
	content: "\e929";
}
.isc-clock:before {
	content: "\e92a";
}
.isc-cog-solid:before {
	content: "\e92b";
}
.isc-cookie-bite-solid:before {
	content: "\e92c";
}
.isc-cookie-solid:before {
	content: "\e92d";
}
.isc-copy:before {
	content: "\e92e";
}
.isc-copyright:before {
	content: "\e92f";
}
.isc-ellipsis-h-solid:before {
	content: "\e930";
}
.isc-ellipsis-v-solid:before {
	content: "\e931";
}
.isc-envelope:before {
	content: "\e932";
}
.isc-envelope-open:before {
	content: "\e933";
}
.isc-envelope-open-solid:before {
	content: "\e934";
}
.isc-exchange-alt-solid:before {
	content: "\e935";
}
.isc-exclamation-circle-solid:before {
	content: "\e936";
}
.isc-exclamation-solid:before {
	content: "\e937";
}
.isc-exclamation-triangle-solid:before {
	content: "\e938";
}
.isc-expand-arrows-alt-solid:before {
	content: "\e939";
}
.isc-expand-solid:before {
	content: "\e93a";
}
.isc-external-link-alt-solid:before {
	content: "\e93b";
}
.isc-external-link-square-alt-solid:before {
	content: "\e93c";
}
.isc-facebook:before {
	content: "\e93d";
}
.isc-facebook-f:before {
	content: "\e93e";
}
.isc-facebook-square:before {
	content: "\e93f";
}
.isc-file:before {
	content: "\e940";
}
.isc-file-alt:before {
	content: "\e941";
}
.isc-file-alt-solid:before {
	content: "\e942";
}
.isc-file-image:before {
	content: "\e943";
}
.isc-file-image-solid:before {
	content: "\e944";
}
.isc-file-pdf:before {
	content: "\e945";
}
.isc-file-pdf-solid:before {
	content: "\e946";
}
.isc-filter-less:before {
	content: "\e947";
}
.isc-filter-more:before {
	content: "\e948";
}
.isc-google:before {
	content: "\e949";
}
.isc-google-play:before {
	content: "\e94a";
}
.isc-google-plus:before {
	content: "\e94b";
}
.isc-google-plus-square:before {
	content: "\e94c";
}
.isc-graduation-cap-solid:before {
	content: "\e94d";
}
.isc-image:before {
	content: "\e94e";
}
.isc-info-circle-solid:before {
	content: "\e94f";
}
.isc-info-solid-1:before {
	content: "\e950";
}
.isc-instagram:before {
	content: "\e951";
}
.isc-laugh:before {
	content: "\e952";
}
.isc-laugh-beam:before {
	content: "\e953";
}
.isc-laugh-beam-solid:before {
	content: "\e954";
}
.isc-laugh-solid:before {
	content: "\e955";
}
.isc-laugh-squint:before {
	content: "\e956";
}
.isc-laugh-squint-solid:before {
	content: "\e957";
}
.isc-laugh-wink:before {
	content: "\e958";
}
.isc-laugh-wink-solid:before {
	content: "\e959";
}
.isc-link-solid:before {
	content: "\e95a";
}
.isc-linkedin:before {
	content: "\e95b";
}
.isc-linkedin-in:before {
	content: "\e95c";
}
.isc-lock-open-solid:before {
	content: "\e95d";
}
.isc-lock-solid:before {
	content: "\e95e";
}
.isc-map:before {
	content: "\e95f";
}
.isc-map-marked-alt-solid:before {
	content: "\e960";
}
.isc-map-marker-alt-solid:before {
	content: "\e961";
}
.isc-map-marker-solid:before {
	content: "\e962";
}
.isc-map-pin-solid:before {
	content: "\e963";
}
.isc-microphone-slash-solid:before {
	content: "\e964";
}
.isc-microphone-solid:before {
	content: "\e965";
}
.isc-minus-circle-solid:before {
	content: "\e966";
}
.isc-minus-solid:before {
	content: "\e967";
}
.isc-minus-square:before {
	content: "\e968";
}
.isc-paperclip-solid:before {
	content: "\e969";
}
.isc-pen-solid:before {
	content: "\e96a";
}
.isc-pencil-alt-solid:before {
	content: "\e96b";
}
.isc-phone-alt-solid:before {
	content: "\e96c";
}
.isc-phone-solid:before {
	content: "\e96d";
}
.isc-phone-square-alt-solid:before {
	content: "\e96e";
}
.isc-phone-square-solid:before {
	content: "\e96f";
}
.isc-play-circle:before {
	content: "\e970";
}
.isc-play-solid:before {
	content: "\e971";
}
.isc-plus-circle-solid:before {
	content: "\e972";
}
.isc-plus-solid:before {
	content: "\e973";
}
.isc-plus-square:before {
	content: "\e974";
}
.isc-redo-alt-solid:before {
	content: "\e975";
}
.isc-redo-solid:before {
	content: "\e976";
}
.isc-reorder-solid:before {
	content: "\e977";
}
.isc-search-minus-solid:before {
	content: "\e978";
}
.isc-search-plus-solid:before {
	content: "\e979";
}
.isc-search-solid:before {
	content: "\e97a";
}
.isc-shopping-bag-solid:before {
	content: "\e97b";
}
.isc-shopping-cart-solid:before {
	content: "\e97c";
}
.isc-smile:before {
	content: "\e97d";
}
.isc-smile-beam:before {
	content: "\e97e";
}
.isc-smile-beam-solid:before {
	content: "\e97f";
}
.isc-smile-solid:before {
	content: "\e980";
}
.isc-smile-wink:before {
	content: "\e981";
}
.isc-smile-wink-solid:before {
	content: "\e982";
}
.isc-sort-alpha-down-alt-solid:before {
	content: "\e983";
}
.isc-sort-alpha-down-solid:before {
	content: "\e984";
}
.isc-sort-alpha-up-alt-solid:before {
	content: "\e985";
}
.isc-sort-alpha-up-solid:before {
	content: "\e986";
}
.isc-sort-amount-down-alt-solid:before {
	content: "\e987";
}
.isc-sort-amount-down-solid:before {
	content: "\e988";
}
.isc-sort-amount-up-alt-solid:before {
	content: "\e989";
}
.isc-sort-amount-up-solid:before {
	content: "\e98a";
}
.isc-sort-down-solid:before {
	content: "\e98b";
}
.isc-sort-numeric-down-alt-solid:before {
	content: "\e98c";
}
.isc-sort-numeric-down-solid:before {
	content: "\e98d";
}
.isc-sort-numeric-up-alt-solid:before {
	content: "\e98e";
}
.isc-sort-numeric-up-solid:before {
	content: "\e98f";
}
.isc-sort-solid-1:before {
	content: "\e990";
}
.isc-sort-up-solid:before {
	content: "\e991";
}
.isc-star:before {
	content: "\e992";
}
.isc-star-half-alt-solid:before {
	content: "\e993";
}
.isc-star-half-solid:before {
	content: "\e994";
}
.isc-star-solid:before {
	content: "\e995";
}
.isc-sync-alt-solid:before {
	content: "\e996";
}
.isc-tag-solid:before {
	content: "\e997";
}
.isc-tags-solid:before {
	content: "\e998";
}
.isc-thumbs-down:before {
	content: "\e999";
}
.isc-thumbs-up:before {
	content: "\e99a";
}
.isc-times-circle:before {
	content: "\e99b";
}
.isc-times-circle-solid:before {
	content: "\e99c";
}
.isc-times-solid:before {
	content: "\e99d";
}
.isc-trash-alt:before {
	content: "\e99e";
}
.isc-twitter:before {
	content: "\e99f";
}
.isc-twitter-square:before {
	content: "\e9a0";
}
.isc-undo-alt-solid:before {
	content: "\e9a1";
}
.isc-undo-solid:before {
	content: "\e9a2";
}
.isc-upload-solid-1:before {
	content: "\e9a3";
}
.isc-upload-solid:before {
	content: "\e9a4";
}
.isc-user:before {
	content: "\e9a5";
}
.isc-user-alt-solid:before {
	content: "\e9a6";
}
.isc-user-circle:before {
	content: "\e9a7";
}
.isc-user-tie-solid:before {
	content: "\e9a8";
}
.isc-video-slash-solid:before {
	content: "\e9a9";
}
.isc-video-solid:before {
	content: "\e9aa";
}
.isc-volume-off-solid:before {
	content: "\e9ab";
}
.isc-volume-up-solid:before {
	content: "\e9ac";
}
.isc-whatsapp:before {
	content: "\e9ad";
}
.isc-whatsapp-square:before {
	content: "\e9ae";
}
.isc-youtube:before {
	content: "\e9af";
}
.isc-youtube-square:before {
	content: "\e9b0";
}
