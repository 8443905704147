body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-y: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
	outline-color: #00aff0;
}

/* ------------------------------- CHAT THEME ------------------------------- */

:root {
	--brand-1: #00aff0;
	--brand-2: #00aff0;
	--brand-3: rgba(0, 175, 240, 0.4);
	--black: #000;
	--white: #fff;
	--gray-1: rgba(0, 0, 0, 0.87);
	--gray-2: rgba(0, 0, 0, 0.54);
	--gray-3: rgba(0, 0, 0, 0.4);
	--gray-4: rgba(0, 0, 0, 0.26);
	--gray-5: rgba(0, 0, 0, 0.2);
	--gray-6: rgba(0, 0, 0, 0.1);
	--gray-7: rgba(0, 0, 0, 0.05);
	--gray-8: rgba(0, 0, 0, 0.03);
	--gray-9: #3b3e4d;
	--gray-10: #8189a9;
	--light-1: #f2f4f5 !important;
	--light-2: #fafafa;
	--light-3: #f7f7fc;
	--blue: #3c4dec !important;
	--gray-dark: #333;
	--green: #62d957;
	--orange: #f70;
	--red: #f56262;
	--yellow: #ffc233;
	--warning: #ff3366;
	--yellow-light: #fff9db;
	--shadow-1: 0 1.5px 3px 0 rgba(0, 0, 0, 0.06), 0 1.5px 3px 0 rgba(0, 0, 0, 0.08);
	--shadow-2: 0 6px 6px 0 rgba(0, 0, 0, 0.03), 0 4px 2px 0 rgba(0, 0, 0, 0.04);
	--shadow-3: 0 6px 6px 0 rgba(0, 0, 0, 0.03), 0 4px 2px 0 rgba(0, 0, 0, 0.04);
	--shadow-4: 0 10px 10px 0 rgba(0, 0, 0, 0.03), 0 8px 4px 0 rgba(0, 0, 0, 0.02);
	--shadow-5: 0 10px 10px 0 rgba(0, 0, 0, 0.03), 0 8px 4px 0 rgba(0, 0, 0, 0.02);
	--rich-text: #366ecc;
	--other-user-message-bubble-backgrond: var(--white);
	--header-background-color: var(--brand-1);
	--header-font-color: var(--white);
	--chat-background-color: var(--light-1);
	--app-background-color: var(--white);
	--card-background-color: var(--white);
	--element-focus-color: var(--brand-1);
}

.ChatApp,
.MessageContainer,
.Navbar,
.Message .Bubble,
.QuickReplies,
.ChatFooter {
	background-repeat: no-repeat;
	background-size: cover;
}

.ChatApp {
	height: 86% !important;
	background-color: var(--chat-background-color) !important;
	display: flex;
	flex: 1;
}

.Navbar {
	background-color: #00aff0 !important;
}

.Navbar .IconBtn {
	color: var(--white);
}

.Navbar-title {
	color: var(--white);
}

.Message.left .Bubble {
	/* Override */
	background-color: var(--other-user-message-bubble-backgrond);
	color: rgba(0, 0, 0, 0.87);
}

.Message.right .Bubble {
	background-color: var(--brand-3);
	color: var(--gray-1);
}

.QuickReplies,
.ChatFooter {
	background-color: rgba(242, 244, 245, 0.95);
}

.Message.left .Bubble {
	border-radius: 4px 20px 20px 20px;
}

.Message.right .Bubble {
	border-radius: 20px 4px 20px 20px;
}

.Card {
	background-color: var(--card-background-color) !important;
	border-radius: 12px;
}

.Btn {
	border-radius: 16px;
}

.QuickReply {
	border-radius: 20px;
}

:root {
	--brand-3: rgba(0, 175, 240, 0.4);
	--brand-1: #00aff0;
	--brand-2: #00aff0;
	--black: #000;
	--white: #fff;
	--gray-1: rgba(1, 1, 1, 0.87);
	--gray-2: rgba(0, 0, 0, 0.54);
	--gray-3: rgba(0, 0, 0, 0.4);
	--gray-4: rgba(0, 0, 0, 0.26);
	--gray-5: rgba(0, 0, 0, 0.2);
	--gray-6: rgba(0, 0, 0, 0.1);
	--gray-7: rgba(0, 0, 0, 0.05);
	--gray-8: rgba(0, 0, 0, 0.03);
	--light-1: #f2f4f5 !important;
	--light-2: #fafafa;
	--light-3: #f7f7fc;
	--blue: #3c4dec !important;
	--gray-dark: #333;
	--green: #62d957;
	--orange: #f70;
	--red: #f56262;
	--yellow: #ffc233;
	--yellow-light: #fff9db;
}

.RecorderToast {
	border-radius: 50% !important;
}

/* Live Agent Override */

#root,
body,
html {
	/* overflow-y: hidden; */
	background: var(--app-background-color);
	min-height: 100vh;
	height: auto !important;
}

.ScrollView-control {
	background-color: white !important;
	margin: 0px 4px 0 4px !important;
	padding: 4px !important;
	font-size: 16px !important;
	box-shadow: 0 2px 2px #baabab !important;

	&:not(:disabled):hover {
		color: #2a2a2a !important;
	}
	& > .Icon {
		fill: #2a2a2a !important;
	}
}

.Input {
	&::placeholder {
		color: #2a2a2a !important;
	}
}

.chat-window-container {
	*:focus,
	input:focus,
	select:focus,
	button:focus {
		outline: 3px solid var(--element-focus-color) !important;
	}
}

.ChatFooter {
	.ScrollView {
		.ScrollView-scroller {
			padding-top: 2px;
			padding-left: 2px;
			padding-right: 2px;
		}
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0;
}
