.message-name {
	font-weight: 600;
}

.message-time {
	font-size: x-small;
	color: grey;
}

.right-align {
	text-align: right;
}

.Bubble {
	background-color: "red";
}
