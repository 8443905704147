footer {
	width: 100%;
	background-color: var(--chat-background-color);
	color: #2d2d2d;
	text-align: center;
	z-index: 10;
	height: 1.5rem;
	vertical-align: center;
	font-size: 11px;
}

.logo {
	max-height: 0.7rem;
	margin-bottom: 2px;
}
