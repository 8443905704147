.fallback-container {
	width: 100%;
	min-height: 50%;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 2rem;
	background-color: white;
}

.gray-background {
	background-color: var(--light-3);
}

.fallback-image {
	max-width: 75%;
	max-height: 75%;
	margin-bottom: 2rem;
}

.fallback-title {
	color: var(--gray-9);
	font-weight: 600;
	font-size: 16px;
}

.fallback-subtitle {
	margin-top: 8px;
	color: var(--gray-10);
	text-align: center;
	font-size: 12px;
}
