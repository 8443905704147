.header-container {
	width: 100%;
	height: 4rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	/* Live Agent Override */
	color: var(--header-font-color);
	background-color: var(--header-background-color);
	padding-left: 20px;
	padding-right: 20px;
	font-size: 16px;
	font-weight: 600;
	border-bottom: 1px solid #f2f4f5 !important;
}

.isc-times-solid:focus-visible {
	outline: 2px white solid;
}

.icon-up {
	display: none;
}

.icon-down {
	display: none;
}

.header-icon {
	font-size: 1.5rem;
	font-weight: 600;
	margin-left: 1rem;
	cursor: pointer;
}

.chevron {
	margin-left: 0;
	margin-right: 0.5rem;
}

@media (max-width: 991px) {
	.hide-on-mobile-view {
		display: none;
	}
}

@media (min-width: 991px) {
	.hide-on-expanded-frame {
		display: none;
	}
}

.title-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.title {
	margin-left: 8px;
}

.hide {
	display: none;
}

.chat-title {
	margin-left: 8px;
	font-size: 24px;
	letter-spacing: 0.2rem;
	font-weight: 700 !important;
}

.name-avatar {
	margin-left: 2px;
}
