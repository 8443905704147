.Card {
	display: flex;
	flex-direction: column;
	height: 99.5%;
}

.carousel-card {
	width: 210px;
}
.card-sender-name {
	padding: 10px 12px;
	font-size: 14px !important;
	font-weight: 600;
}
.chat-body {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
}

.chat-body > .CardTitle {
	font-size: 14px;
}

.message-time-card {
	font-size: x-small;
	color: grey;
	padding: 5px 12px;
}

.carousel > .CardTitle-title {
	font-size: 14px;
}

.carousel > .RichText {
	font-size: 12px;
}
