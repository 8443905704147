.chat-list-container {
	min-width: 25%;
	width: 25%;
	height: 100%;
	display: flex;
	flex-direction: column;
	border-right: 1px solid var(--gray-6);
}

@media (max-width: 991px) {
	.chat-list-container {
		width: 100%;
	}
}

.search-input-container {
	width: 100%;
	padding: 15px 15px 15px 15px;
}

.chat-list-body-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: auto;
}

.list-item-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	height: 30%;
}

.list-item-selected {
	background-color: var(--gray-8);
}

.Input-counter {
	visibility: hidden;
}

.loader-container {
	display: flex;
	flex: 1;
	height: 10rem;
	width: 100%;
	margin: auto;
	align-items: center;
	justify-content: center;
}

.ListItem-content {
	width: 100%;
}

.avatar-and-body-container {
	display: flex;
	flex: 1;
	align-items: center;
	flex-direction: row;
	justify-content: flex-start;
	max-width: 85%;
}

.body-container {
	width: 90%;
	color: #0f1828;
	display: flex;
	flex-direction: column;
	padding-left: 10px;
}

.item-title {
	font-size: 14px;
	text-overflow: ellipsis;
	max-width: 90%;
	white-space: nowrap;
	overflow: hidden;
}

.item-subtitle {
	font-size: 12px;
	font-weight: 400;
	color: #565353;
	text-overflow: ellipsis;
	max-width: 90%;
	white-space: nowrap;
	overflow: hidden;
}

.item-bold {
	font-weight: 550;
}

.unread-count-container {
	margin-top: auto;
	margin-bottom: auto;
}

.unread-count {
	background-color: var(--red);
	color: var(--white);
	font-weight: 700;
	font-size: 13px;
	border-radius: 50%;
	padding: 4px;
	height: 22px;
	width: 24px;
	margin: auto;
	text-align: center;
}

.waiting-count {
	background-color: var(--red);
	color: var(--white);
	font-weight: 700;
	font-size: 13px;
	border-radius: 50%;
	padding: 4px;
	height: 22px;
	width: 24px;
	margin: auto;
	text-align: center;
}

.chat-list-item-center {
	text-align: center;
}

.Notice {
	top: auto;
	bottom: 2rem;
}

.ListItem {
	cursor: pointer;
}

.tabs-and-unassigned-buttons-wrapper {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.pulse {
	animation-name: color;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-direction: alternate-reverse;
	animation-timing-function: ease;
}

@keyframes color {
	to {
		background-color: #fef5ca;
	}
}
