.chat-window-container {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.chat-window-body {
	display: flex;
	flex: 1;
}

@media (max-width: 991px) {
	.chat-window-container {
		width: 100%;
		position: absolute;
		left: 0;
		bottom: -1px;
		top: 0;
		right: 0;
		/* display: none; */
	}
	.hide-chat-window {
		visibility: hidden;
	}
}

.footer-offset {
	padding-bottom: 1rem;
}

.PullToRefresh:focus-visible,
.QuickReply:focus-visible,
.Btn:focus-visible,
.ScrollView-scroller:focus-visible,
.RichText > a:focus-visible,
.Recorder-btn:focus-visible,
.Composer-input:focus-visible {
	outline: 2px solid var(--brand-1);
}

.PullToRefresh:focus-visible,
.QuickReply:focus-visible,
.ScrollView-scroller:focus-visible {
	margin: 2px;
}

.fallback {
	height: 92%;
}

.ScrollView-scroller:focus-visible {
	padding-bottom: 0px;
}

.RichText > a {
	color: var(--rich-text);
}

.navbar {
	color: white;
	text-align: left;
}

.chat-window-load-more-button {
	margin: auto;
}

.system-message-container {
	margin: auto;
}

.system-message {
	background-color: #fef5ca;
	padding: 3px 8px;
	border-radius: 6px;
	text-align: center;
}
